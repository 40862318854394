
  import { defineComponent, ref, onMounted, computed } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  // import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
  import AddQuestionModal from "@/components/modals/forms/AddQuestionModal.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from '@/store/enums/StoreEnums' 
  import { useStore } from "vuex";
  export default defineComponent({
    name: "language-listing",
    components: {
      Datatable,
      AddQuestionModal,
      // ExportCustomerModal,
      // AddCustomerModal,
    },
    setup() {
      const store = useStore()
      const checkedCustomers = ref([]);
      const tableHeader = ref([
        // {
        //   key: "checkbox",
        //   sortable: false,
        // },
        {
          name: "Savollar",
          key: "question",
          sortable: true,
        },
        {
          name: "Javoblar",
          key: "answers",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          // name: "Actions",
          key: "actions",
        },
      ]);
  

      const dataTable = computed(()=>{
        return store.getters.alQuestions
      })

      onMounted(async() => {
        setCurrentPageBreadcrumbs("Section Listing", ["Apps"]);
        await store.dispatch(Actions.ALL_QUESTIONS, {params:{limit: 10}});
      });


      const perPage = async(val:number)=>{
        await store.dispatch(Actions.ALL_QUESTIONS, {params:{limit: val}});
      }

      const edit = ref<boolean>(false)
      const itemId = ref<string>("")

      const editItem = (item: string)=>{
        edit.value = true;
        itemId.value = item
        
      }
      const changeStatus = async(item: string, status: number)=>{
        await store.dispatch(Actions.CHANGE_QUESTION_STATUS, {item, status});
      }
  
      const deleteFewCustomers = () => {
        checkedCustomers.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCustomers.value.length = 0;
      };
  
      const deleteCustomer = async(id:string) => {
        await store.dispatch(Actions.DEL_TAG, id);
      };
  
      const search = ref<string>("");
      const searchItems = async() => {
        await store.dispatch(Actions.ALL_QUESTIONS, {params:{title: search.value}});
      };
  
      const count = computed(()=>{
        return store.getters.getCount
      })
      

      return {
        tableHeader,
        deleteCustomer,
        search,
        searchItems,
        checkedCustomers,
        deleteFewCustomers,
        dataTable, edit, editItem, itemId, perPage, changeStatus, count
      };
    },
  });
  