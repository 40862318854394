
  import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
  import { hideModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { Actions } from "@/store/enums/StoreEnums";
  import { useStore } from "vuex";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "add-section-modal",
    components: {},
    props: ['edit', 'item'],
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const addLanguageModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const loading = ref<boolean>(false);
      const add = ref<boolean>(true)
      const formData = ref({
        language: "",
        answers: [{answer: ''}, {answer: ''}, {answer: ''}],
        question: null
      });


      const resetForm = ()=>{
        formData.value.answers = [{answer: ''}],
        formData.value.question = null
      }

      const swiching = ref("Category")
  
      const rules = ref({
        name: [
          {
            required: true,
            message: "Zone name is required",
            trigger: "blur",
          },
        ],
        subcategory: [
          {
            required: true,
            message: "Subcategory is required",
            trigger: "change",
          },
        ],
      });



      watchEffect(()=>{
        add.value = !props.edit
        if(!add.value){
          ApiService.get('questionnaire/'+ props.item).then((res)=>{
              formData.value = res.data;
          })
        }else{
          resetForm()
        }
      })

  
      const submit = () => {
        if (!formRef.value) {
          return;
        }
  
        formRef.value.validate((valid) => {
          if (valid) {
            loading.value = true;
            if(add.value){
              store.dispatch(Actions.ADD_QUESTION, formData);
            }else{
              store.dispatch(Actions.EDIT_QUESTION, formData).then(()=>{
                Swal.fire({
                  text: "Jarayon muvaffaqiyatli bajarildi!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Yaxshi",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  resetForm();
                  hideModal(addLanguageModalRef.value);
                  add.value = true
                })
              }).catch(err =>{
                Swal.fire({
                  text: "Xatolik yuz berdi. Iltimos qaytadan urinib ko'ring",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Yaxshi",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
            }
            
            setTimeout(() => {
              loading.value = false;
              
            }, 200);
          } else {
            
            return false;
          }
        });
      };
      
      const languages = computed(()=>{
        return store.getters.langs
      })


      const addAnswer = () =>{
        formData.value.answers = [...formData.value.answers, {answer: ''}]
      }

      const removeAnswer = (i) =>{
        formData.value.answers.splice(i, 1)
      }
    
  
      onMounted(async()=>{
        await store.dispatch(Actions.ALL_LANGUAGES);
      })
      return {
        formData, rules, submit, formRef, loading,
        addLanguageModalRef, add, languages, swiching, addAnswer, removeAnswer
      };
    },
  });
  